<template>
  <div class="dev-onboard">
    <Navbar />
    <main>
      <div style="left: 50%; right: 0; margin-top: 10%">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </main>
  </div>
</template>
<script>
import Axios from "axios";
import { mapState, mapActions } from "vuex";
import mixPanel from "@/mixins/mixPanel";
import postLogin from "@/mixins/postLogin";
import Navbar from "@/components/Navbar/onboarding_navbar";

export default {
  mixins: [postLogin, mixPanel],
  components: {
    Navbar,
  },
  data: () => ({
    isVerifyingUserDetails: false,
  }),
  created() {
    if (this.isVerifyingUserDetails === false) {
      localStorage.removeItem("engineerRole");
      this.fetchUserDetails();
    }
  },
  mounted() {},
  methods: {
    ...mapActions("userManagement", [
      "setAutheticatedUser",
      "setUserReferralCode",
    ]),
    ...mapActions("devOnboarding", [
      "setLinkedinProfile",
      "setRegistrationToken",
    ]),
    async fetchUserDetails() {
      if (this.$route.query.token) {
        const token = this.$route.query.token;
        const header = {
          headers: { Authorization: `Bearer ${token}` },
        };
        // Endpoint payload
        // let regPayload = {
        //   ...(this.referralCode
        //   && { referralCode: this.referralCode })
        // };
        let regPayload = {};
        regPayload = this.referralCode
          ? { ...regPayload, referralCode: this.referralCode }
          : { ...regPayload };
        regPayload = this.registrationToken
          ? { ...regPayload, reg_token: this.registrationToken }
          : { ...regPayload };
        regPayload = this.customerType
          ? {
              ...regPayload,
              user_type: this.$route.query.saved
                ? this.$route.query.user_type
                : this.customerType,
            }
          : { ...regPayload };

        this.isVerifyingUserDetails = false;
        // alert(JSON.stringify(regPayload));
        await Axios.post(
          this.$baseUrl + `/onboarding/get/user`,
          regPayload,
          header
        )
          .then((response) => {
            // console.log("response", response);
            localStorage.removeItem("engineerRole");

            this.isVerifyingUserDetails = true;
            if (response.data.status == 1) {
              let {
                user,
                team,
                token: newToken,
                nylas,
                founders,
              } = response.data.data;

              //console.log(response.data.data);
              user.token = newToken ? newToken : token;

              var Newuser = {
                ...user,
                nylas: nylas,
              };

              // console.log("new user set", Newuser);
              this.setLogInUser(Newuser);
              localStorage.setItem(
                "grupa_user_founder",
                JSON.stringify(founders)
              );
              // console.log(user.user_type)
              if (user.user_type == 1) {
                let payloadData = { data: null, profileUrl: null };
                // clear saved linkedinProfile
                this.setLinkedinProfile(payloadData);
                // console.log(team)
                if (team) {
                  this.teamActivePage(Newuser, team);
                } else {
                  this.updateTeam(token, Newuser);
                }
              } else if (user.user_type == 2 || !user.user_type) {
                this.$router.push({ name: "user_type" });
              }
              // Set event for expert or founder
              let event =
                user.user_type == 1 ? "GoogleSignUpExpert" : "GoogleSignUp";
              // Set event for team members
              event = this.registrationToken ? "GoogleSignInJoinTeam" : event;
              user.event = event;
              this.mixPanelCreateUser(user);
              // Clear referral code
              this.setUserReferralCode(null);
              // Clear registration token
              this.setRegistrationToken(null);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
    teamActivePage(user, team) {
      const { activation_level: team_activation_level, interests } = team;
      const { activation_level: user_activation_level, team_lead } = user;
      // team lead

      // console.log(team_activation_level)
      //console.log(user_activation_level)
      //console.log(team_lead)

      if (team_lead == 1) {
        if (team_activation_level == 0 && user_activation_level < 2) {
          this.$router.push({ name: "linkedin_profile" });
        } else if (
          team_activation_level == 0 &&
          user_activation_level >= 2 &&
          interests == null
        ) {
          this.$router.push({ name: "team_interests" });
        } else if (
          team_activation_level &&
          user_activation_level >= 2 &&
          interests == null
        ) {
          this.$router.push({ name: "team_interests" });
        } else if (
          !team_activation_level &&
          user_activation_level >= 2 &&
          interests
        ) {
          this.$router.push({ name: "dev_dashboard" });
        } else if (team_activation_level >= 1 && user_activation_level >= 2) {
          this.$router.push({ name: "dev_dashboard" });
        } else if (team_activation_level >= 1 && !user_activation_level) {
          this.$router.push({ name: "dev_dashboard" });
          // logoutUser();
          // window.location.reload();
        } else {
          // console.log( this.$router.push({ name: "dev_dashboard" }))
          this.$router.push({ name: "dev_dashboard" });
        }
      }
      // Team member
      if (team_lead == 0) {
        if (user_activation_level < 2) {
          this.$router.push({ name: "linkedin_profile" });
        } else if (user_activation_level >= 2) {
          this.$router.push({ name: "dev_dashboard" });
        }
      }
    },
    setLogInUser(user) {
      let payload = {
        user,
        isAuthenticated: true,
        userType: user.user_type,
      };
      localStorage.setItem("grupa_user", JSON.stringify(user));
      this.setAutheticatedUser(payload);
    },
    updateTeam(token) {
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      Axios.get(this.$baseUrl + `/onboarding/set/usertype/1`, header)
        .then((response) => {
          if (response.data.status == 1) {
            const { user } = response.data.data;
            this.updateLoginUser(user);
            if (user.activation_level >= 2) {
              this.$router.push({ name: "team_option" });
            } else {
              this.$router.push({ name: "linkedin_profile" });
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    updateLoginUser(user) {
      let payload = {
        user,
        isAuthenticated: true,
        userType: user.user_type,
      };
      this.setAutheticatedUser(payload);
    },
  },
  computed: {
    ...mapState("userManagement", ["referralCode", "customerType"]),
    ...mapState("devOnboarding", ["registrationToken"]),
  },
};
</script>
<style scoped></style>
